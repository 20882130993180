import styled from "styled-components";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
	AccordionSummaryProps
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MenuBackground from "../../../assets/MenuBackground.png";
import { Box } from "@mui/material";

export const MenuContainer = styled.div`
	margin: 20px;
`;

export const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
	border: "0.5px solid #EBEBEB",
	"&:not(:last-child)": {
		borderBottom: 0
	},
	"&:before": {
		display: "none"
	}
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(() => ({
	backgroundColor: "rgba(0, 0, 0, .03)",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)"
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: 5
	}
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: 10
}));

export const MobileContainer = styled.div`
	position: relative;
	margin: 15px;
	display: none;

	@media (max-width: 819px) {
		display: unset;
	}
`;

export const DesktopContainer = styled.div`
	display: none;
	@media (min-width: 820px) {
		display: flex;
	}
`;

export const FirstPanel = styled.div`
	width: 47.5%;
`;

export const SecondPanel = styled.div`
	position: relative;
	width: 52.5%;
	background: #ccf7f4;
	background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0) 20vh,
			#ffffff 95.91%
		),
		url(${MenuBackground});
	background-repeat: no-repeat;
	background-size: cover;
`;

export const CategoryContainer = styled.div`
	margin: 30px 0px 30px 16px;
	@media (min-width: 1020px) {
		margin-left: 30px;
	}
`;

export const SubMenuContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const CategoryDescription = styled.div`
	width: "33%";
	flex-shrink: 0;
	color: #113b62;
	font-size: 20px;
	font-weight: 400;
	font-family: "Baloo";
`;

export const UserContainer = styled(Box)`
	padding: 20px 30px;
	max-width: 540px;
	position: sticky;
	top: 62%;
	width: 100%;
	box-sizing: border-box;
	margin: 0px auto;
	overflow: 251px;
	display: grid;
	align-items: end;

	@media (max-height: 550px) {
		top: 10%;
		min-height: 305px;
	}
`;
