/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "components/Buttons/ThemeButton";
import classNames from "classnames";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDIPAction, useDIPRewards } from "components/DIP/hooks";
import { useAppSelector } from "hooks";
import { Fragment } from "react";
import { selectDipState, selectPoisBountyV2State } from "redux/slices";
import { CrownIcon } from "utils/svgIcons";
import { getClassNameVariant } from "components/DIP/helper";
import { getFlexBasis } from "../helper";
import { Typography } from "@mui/material";
import {
	OffersStyled,
	ItemImage,
	Items,
	RewardItem,
	RewardAmount,
	Footer,
	LoyaltyPoints,
	OfferItem
} from "./styles";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { sortRewardsWithSpecificId } from "helpers";
import { CardFinalMessage } from "styles/general_styles";
import { useWebStoreContext } from "contexts";

interface OffersProps {}

export const Offers: React.FC<OffersProps> = () => {
	const { currentDip, claimRewardProcess } = useAppSelector(selectDipState);
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const { userTracker } = useWebStoreContext();
	const { getDIPReward } = useDIPRewards();
	const { onAction } = useDIPAction();
	const { loading } = claimRewardProcess;
	//const isLowWidth = useMediaQuery("(max-width:720px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const isMobile = isPortrait;
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;
	//const arrayTest = [1, 2, 3, 4, 5];

	if (typeof currentDip === "undefined") {
		return <Fragment />;
	}
	const { buttons: data, dip } = currentDip;
	const bouncyChestClass =
		dip.triggerType === "bouncyChestClick" ? "bouncy-chest" : "";

	return (
		<OffersStyled
			className={classNames([
				getClassNameVariant(data?.length as number),
				bouncyChestClass
			])}
		>
			{data?.map((item) => {
				const { price, buyType, buttonLabel, rewards, loyaltyPointReward } =
					getDIPReward(item);
				return (
					<>
						<OfferItem
							key={item.id}
							className={classNames([
								getClassNameVariant(data.length as number),
								loyaltyPointReward && "loyalty-reward",
								"item-reward",
								bouncyChestClass
							])}
						>
							{showOfferId && (
								<Typography
									sx={{
										fontWeight: 700,
										color: "red",
										fontSize: "12px",
										height: "auto",
										textAlign: "center"
									}}
								>
									{item.loyaltyOfferId ? item.loyaltyOfferId : item.offerId}
								</Typography>
							)}
							<Items
								className={classNames([
									getClassNameVariant(data.length as number),
									"bundle-rewards-item"
								])}
							>
								{sortRewardsWithSpecificId(
									bouncyChestClass
										? sortRewardsWithSpecificId(rewards, "volcano", "first")
										: rewards,
									"random",
									"last"
								).map(({ imageUrl, description }, key) => {
									const flexBasis = getFlexBasis(
										data,
										rewards,
										isMobile,
										bouncyChestClass
									);

									return (
										<RewardItem
											className={bouncyChestClass}
											key={key}
											width={flexBasis + "%"}
										>
											<ItemImage src={imageUrl} alt="" />
											<RewardAmount className={bouncyChestClass}>
												{bouncyChestClass && "x"}
												{description}
											</RewardAmount>
										</RewardItem>
									);
								})}
								{/* {arrayTest.map(({}, key) => {
									const flexBasis = getFlexBasis(
										data,
										arrayTest,
										isMobile,
										triggerClass
									);

									return (
										<RewardItem
											className={triggerClass}
											key={key}
											width={flexBasis + "%"}
										>
											<ItemImage
												src={
													"https://cdn.solitairetripeaks.com/WebStore/reward_images/coin.png"
												}
												alt=""
											/>
											<RewardAmount className={triggerClass}>
												{triggerClass && "x"}
												{200}
											</RewardAmount>
										</RewardItem>
									);
								})} */}
							</Items>

							<Footer
								className={classNames([
									loyaltyPointReward && "loyalty-reward",
									getClassNameVariant(data.length as number),
									bouncyChestClass
								])}
							>
								{loyaltyPointReward && (
									<LoyaltyPoints>
										<div>
											Earn <CrownIcon width={16} />
											{loyaltyPointReward?.toLocaleString("en-US")}
										</div>
										{isPoisActive && (
											<CardFinalMessage color="#FBBD05">
												Contribute to Poi’s Bounty
											</CardFinalMessage>
										)}
									</LoyaltyPoints>
								)}
								{!bouncyChestClass && (
									<Button disabled={loading} onClick={() => onAction(item)}>
										{!buttonLabel ? (
											<Fragment>
												{buyType === "loyalty_points" && (
													<CrownIcon width={16} />
												)}
												{price}
												{buyType === "usd" && " USD"}
											</Fragment>
										) : (
											buttonLabel
										)}
									</Button>
								)}
							</Footer>
						</OfferItem>
						{bouncyChestClass && (
							<Button
								sx={{ width: "70%" }}
								disabled={loading}
								onClick={() => onAction(item)}
							>
								{!buttonLabel ? (
									<Fragment>
										{buyType === "loyalty_points" && <CrownIcon width={16} />}
										{price}
										{buyType === "usd" && " USD"}
									</Fragment>
								) : (
									buttonLabel
								)}
							</Button>
						)}
					</>
				);
			})}
		</OffersStyled>
	);
};
