import { Grid, useMediaQuery } from "@mui/material";
import { onClosePopUp } from "redux/slices";
import { useAppDispatch, useImagePath } from "hooks";
import { isMobile } from "react-device-detect";
import title from "assets/bouncy-chest/popup-title.png";
import headerImg from "assets/bouncy-chest/popup-bg.png";
import { HeaderTitle, Body, Button, Container, Header, Title } from "./styles";
import BackToGameButton from "components/Buttons/BackToGameButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import CloseIconButton from "components/AppModalDialogsV2/Dialogs/lib/CloseIconButton";
import { bouncyChestApi } from "redux/apis/bouncyChest";

interface BouncyChestRewardProps {}

export const BouncyChestReward: React.FC<BouncyChestRewardProps> = () => {
	const dispatch = useAppDispatch();
	const { getImage } = useImagePath("local");
	const headImageUrl = getImage(headerImg);
	const titleUrl = getImage(title);
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const scale = useCalculateScale(378, 380);

	const onClose = () => {
		dispatch(
			bouncyChestApi.util.updateQueryData("getChestState", "", (draft) => {
				return {
					...draft,
					isActiveSale: false
				};
			})
		);
		dispatch(onClosePopUp());
	};

	return (
		<Container scale={scale}>
			<CloseIconButton onClose={onClose} />
			<Header data-image={headImageUrl}>
				<HeaderTitle src={titleUrl} />
			</Header>
			<Body>
				<Title>
					Your items are waiting in your in-game inbox to be collected!
				</Title>

				<Grid container spacing={isPortrait ? 2 : 1}>
					<Grid
						item
						xs={isMobile ? 6 : 12}
						sm={isMobile ? 6 : 12}
						display="flex"
						justifyContent="center"
					>
						<Button
							variant="contained"
							themeColor="primary"
							sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							onClick={() => onClose()}
						>
							Continue Shopping
						</Button>
					</Grid>
					{isMobile && (
						<Grid item xs={6} sm={6} display="flex" justifyContent="center">
							<BackToGameButton
								source="endless_offer_dialog"
								themeColor="secondary"
								sx={{ minWidth: { xs: "100%", sm: "100%" } }}
							/>
						</Grid>
					)}
				</Grid>
			</Body>
		</Container>
	);
};
