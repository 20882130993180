/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from "react-redux";
import {
	ChestImage,
	Container,
	StarBurst
	//SuperOfferTitle,
	//Title
} from "./styles";
import chest from "assets/bouncy-chest/chest.gif";
import { RootState } from "redux/store";
import { useLocation } from "react-router-dom";
import starBurst from "assets/club-gifts-galore/start-burst-reward.png";
import { onDIPtrigger, onOpenPopUp, selectDipState } from "redux/slices";
import { useAppDispatch, useAppSelector } from "hooks";
import { BouncyChestResponse } from "interfaces/bouncy-chest";
import { useGetChestStateQuery } from "redux/apis/bouncyChest";
import useNewDay from "hooks/useNewDay";
import { useEffect } from "react";
import { useWebStoreContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs as IDialogVariants } from "interfaces/dialog/variant";
import { offersApi, useGetOffersQuery } from "redux/apis";

interface Props {
	from?: string;
}

export const BouncyChest: React.FC<Props> = ({ from }) => {
	const location = useLocation();
	const isMegaMenuOpen = useSelector(
		(state: RootState) => state.megaMenu.isOpen
	);
	const isHomePage = location.pathname === "/";
	const dispatch = useAppDispatch();
	const { newDay } = useNewDay();
	const { authToken } = useWebStoreContext();
	const { openDialog } = useDialogModalRedux();
	const { data: dipData } = useAppSelector(selectDipState);
	const { nowTime } = useWebStoreContext();

	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: true
	});

	const {
		data,
		isLoading,
		refetch
	}: {
		data?: BouncyChestResponse;
		isLoading: boolean;
		refetch: () => void;
	} = useGetChestStateQuery("");

	const onClick = () => {
		if (!authToken?.value) {
			openDialog(IDialogVariants.LOGIN_TO_CONNECT);
			return;
		}

		if (!isDisabled()) {
			dispatch(onDIPtrigger("bouncyChestClick"));
		}
	};

	const isDisabled = () => {
		return (
			isLoading ||
			!data?.isActiveSale ||
			(authToken?.value &&
				(!purchaseOffers ||
					!dipData?.dips?.find(
						(d) =>
							d.dip.triggerType === "bouncyChestClick" &&
							new Date(d.dip.endTime) > new Date(nowTime?.format() || "")
					)))
		);
	};

	useEffect(() => {
		if (newDay) {
			refetch();
		}
	}, [newDay]);

	if (from === undefined && (isMegaMenuOpen || isHomePage)) {
		return null;
	}

	if (isDisabled()) {
		return null;
	}

	return (
		<Container
			className={`animate animate__animated animate__fadeIn ${from}`}
			onClick={onClick}
			filter={isDisabled() ? 1 : 0}
		>
			<ChestImage className={`${from}`} src={chest} />
			<StarBurst className={`${from}`} src={starBurst}></StarBurst>
		</Container>
	);
};
