import UserProfile from "components/UserProfile";
import Box from "@mui/material/Box";
import PoisBountyWidget from "components/PoisBountyWidget";
import PoisBountyWidgetV2 from "components/PoisBountyWidgetV2";
import { Fragment, useEffect } from "react";
import { StoreButtons, SubMenu, MenuLinks } from "./lib";
import { Drawer, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { setOpen } from "redux/slices/mega-menu";
import { categories } from "./categories";
import { getSaleTags } from "redux/slices";
import { useEnvContext } from "contexts";
import { FeaturesFlags } from "helpers";
import {
	CategoryContainer,
	CategoryDescription,
	DesktopContainer,
	FirstPanel,
	MobileContainer,
	SecondPanel,
	SubMenuContainer,
	UserContainer
} from "./styles";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BouncyChest } from "components/BouncyChest";

export const MegaMenu = () => {
	const isOpen = useSelector((state: RootState) => state.megaMenu.isOpen);
	const dispatch = useDispatch();
	const { isFeatureFlagEnabled } = useEnvContext();
	const toggleDrawer = (state: boolean) => dispatch(setOpen(state));

	useEffect(() => {
		//Effect to prevent removal of Keepmounted on Drawer
		const paperContainer = document.querySelector("#MenuPaper");
		if (paperContainer) {
			paperContainer.scrollTop = 0;
		}
	}, [isOpen]);

	useEffect(() => {
		//Effect to close the drawer when path changes
		const handleBrowserButton = () => {
			if (isOpen) {
				toggleDrawer(false);
			}
		};
		window.addEventListener("popstate", handleBrowserButton);
		return () => {
			window.removeEventListener("popstate", handleBrowserButton);
		};
	}, [isOpen]);

	useEffect(() => {
		dispatch(getSaleTags());
	}, []);

	return (
		<Drawer
			anchor="top"
			open={isOpen}
			onClose={() => toggleDrawer(false)}
			BackdropProps={{ style: { opacity: 0 } }}
			ModalProps={{
				keepMounted: true
			}}
			PaperProps={{
				id: "MenuPaper",
				sx: {
					top: "65px",
					maxHeight: "90%"
				}
			}}
		>
			<MobileContainer>
				{categories?.map((category) => (
					<SubMenu categoryId={category.id} key={category.id} />
				))}
				<UserProfile />
				<StoreButtons />
				<MenuLinks />

				<div
					style={{
						position: "fixed",
						margin: "5px",
						right: "0px",
						bottom: "8%"
					}}
				>
					<BouncyChest from="mega-menu" />
				</div>
			</MobileContainer>

			<DesktopContainer>
				<FirstPanel>
					{categories?.map((category) => (
						<CategoryContainer key={category.id}>
							{category.id !== 1 && (
								<Fragment>
									<CategoryDescription>
										{category.description}
									</CategoryDescription>
									<Divider sx={{ padding: "5px" }}></Divider>
								</Fragment>
							)}
							<SubMenuContainer>
								<SubMenu categoryId={category.id} />
							</SubMenuContainer>
						</CategoryContainer>
					))}
					<StoreButtons />
					<MenuLinks />
				</FirstPanel>
				<SecondPanel>
					<Box sx={{ width: "100%" }}>
						<Box sx={{ overflowX: "auto" }}>
							{isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2) ? (
								<PoisBountyWidgetV2
									style={{ margin: "0 auto", marginTop: "40px" }}
									isPortrait={false}
									isOnMegaMenu={true}
								/>
							) : (
								isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY) && (
									<PoisBountyWidget
										style={{ margin: "0 auto", marginTop: "40px" }}
										isPortrait={false}
										isOnMegaMenu={true}
									/>
								)
							)}
						</Box>
					</Box>
					<UserContainer>
						<div
							style={{
								width: "100%",
								justifyContent: "right",
								display: "flex",
								position: "relative"
							}}
						>
							<BouncyChest from="mega-menu" />
						</div>
						<UserProfile />
					</UserContainer>
				</SecondPanel>
			</DesktopContainer>
		</Drawer>
	);
};
