import { PopUpVariant } from "interfaces";
import { Fragment } from "react";
import {
	GrantRewardCollected,
	PBUncollectedRewards,
	PBLearnMore,
	PBRewardCollected,
	PBFTUE,
	LoyaltyPointsAdded,
	CGGMoreInfo,
	CGGEarnedReward,
	DailyRewardEarned,
	DailyRewardStreakBreak,
	DailyRewardLoyaltyPoints,
	PBFTUEV2,
	PBClaimAllRewards,
	EndlessOfferReward,
	DripOffer,
	DripOfferInfo,
	DripOfferReward,
	DripOfferMissedEvent,
	DripOfferEnd
} from "./lib";
import { BouncyChestReward } from "./lib/BouncyChestReward";

export const variantLoader = (variant: PopUpVariant): JSX.Element => {
	switch (variant) {
		case "grant-reward-collected":
			return <GrantRewardCollected />;
		case "pb-uncollected-rewards":
			return <PBUncollectedRewards />;
		case "pb-learn-more":
			return <PBLearnMore />;
		case "pb-reward-collected":
			return <PBRewardCollected />;
		case "pb-reward-collected-v2":
			return <PBRewardCollected />;
		case "pb-pbftue":
			return <PBFTUE />;
		case "pb-pbftue-v2":
			return <PBFTUEV2 />;
		case "pb-claim-all-rewards":
			return <PBClaimAllRewards />;
		case "loyalty-points-added":
			return <LoyaltyPointsAdded />;
		case "cgg-more-info":
			return <CGGMoreInfo />;
		case "cgg-earned-reward":
			return <CGGEarnedReward />;
		case "daily-login-earned-reward":
			return <DailyRewardEarned />;
		case "daily-login-streak-break":
			return <DailyRewardStreakBreak />;
		case "daily-login-streak-loyalty-points":
			return <DailyRewardLoyaltyPoints />;
		case "endless-offer-reward-collected":
			return <EndlessOfferReward />;
		case "drip-offer":
			return <DripOffer />;
		case "drip-offer-info":
			return <DripOfferInfo />;
		case "drip-offer-reward":
			return <DripOfferReward />;
		case "drip-offer-missed-event":
			return <DripOfferMissedEvent />;
		case "drip-offer-end":
			return <DripOfferEnd />;
		case "bouncy-chest-reward-collected":
			return <BouncyChestReward />;

		default:
			return <Fragment />;
	}
};
