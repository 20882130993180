import styled, { keyframes } from "styled-components";

export const Container = styled.div<{ filter?: number }>`
	position: relative;
	width: 105px;
	display: flex;
	cursor: pointer;
	filter: grayscale(${(props) => props.filter});
	//test
`;

export const ChestImage = styled.img`
	width: 100%;
	position: relative;
	bottom: 0px;
	object-fit: contain;
	z-index: 2;
`;

export const StarBurstFrames = keyframes`
  from {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
`;

export const StarBurst = styled.img`
	animation: ${StarBurstFrames} 20s linear infinite;
	position: absolute;
	width: 170%;
	top: 50%;
	left: 50%;
	z-index: 1;
	opacity: 0.8;
	//display: none;

	/* &.mega-menu {
		display: unset;
	} */
`;
